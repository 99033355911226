import styled from "styled-components"
import { TextOverText } from "../../TextOverText"
import { SmallText } from "../../TextOverText/TextOverText.style"

const WrapperBase = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  z-index: 5;
  display : none;
`

export const Wrapper = styled(WrapperBase)`
  width: 60%;
  height: auto;
  padding: 20px;
  margin: 1em;
  text-align: center;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  &.mobile {
    flex-direction: column;
    width: 90%;
    padding: 5px;
    margin: 20px auto;
  }
`

export const Title = styled(TextOverText)`
  margin: auto;
  ${SmallText} {
    font-weight: bold;
    margin-bottom: -20px;
  }
`

export const ZohoIframe = styled.iframe`
  height: 180px;
  width: 310px;
  margin: auto;
  //neutralise le margin de 30px de l'iframe
  margin-top: -25px;
  border: none;
`
