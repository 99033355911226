import { useStaticQuery, graphql } from "gatsby"

export const useInfoBarQuery = () => {
  const data = useStaticQuery(
    graphql`
      query useBandeauQuery {
          strapiGmHeader {
            BandeauReassurance {
              Image1 {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 65
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                url
                alternativeText
              }
              Image2 {
                url
                alternativeText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 65
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              Image3 {
                url
                alternativeText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 65
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              Image4 {
                url
                alternativeText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 65
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              Text1
              Text2
              Text3
              Text4
            }
          }
      }
    `
  )
  return data
}
