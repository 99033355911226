import React from "react"
import CookieConsent, { Cookies } from "react-cookie-consent"
import { WrapperCookie, Texte } from "./CookieBanner.style"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { useDetectDevice } from "../../../../lib/useDetectDevice"

interface Props {
  className?: string
}

export function CookieConsentBannerView({ className }: Props) {
  const location = useLocation()
  initializeAndTrack(location)

  const isMobile = useDetectDevice()
  let classMobile = (className && className) + " " + (isMobile && "mobile")

  return (
    <WrapperCookie className={classMobile}>
      <CookieConsent
        location="bottom"
        cookieName="gatsby-gdpr-google-analytics"
        style={{ background: "#efefef", width: "inherit", left: "inherit" }}
        expires={180}
        buttonText="Autoriser"
        buttonStyle={{
          background: "black",
          color: "white",
          fontSize: "13px",
          border: "0.2px",
          borderRadius: "2px",
        }}
        enableDeclineButton
        declineButtonText="Refuser"
        declineButtonStyle={{
          background: "white",
          color: "black",
          border: "0.2px solid black",
          borderRadius: "2px",
        }}
      >
        <Texte>
          Grandes Marques utilise des cookies afin de vous offrir une meilleure
          expérience utilisateur. Avec votre consentement, vous autorisez
          l'utilisation de cookies conformément à la politique de
          confidentialité.
        </Texte>
        <Texte>
          <span style={{ fontSize: "14px" }}>
            Pour plus d'informations, rendez vous sur la page Politique relative
            aux cookies.
          </span>
        </Texte>
      </CookieConsent>
    </WrapperCookie>
  )
}
