import { useStaticQuery, graphql } from "gatsby"

export const useHeaderQuery = () => {
  const data = useStaticQuery(
    graphql`
    query HeaderQuery {
      strapiGmHeader {
        HamburgerMobile {
          Image {
            name
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 24
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            alternativeText
            id
          }
        }
        BandeauHeader {
          Logo {
            name
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            alternativeText
            id
          }
          MoteurRecherche {
            Image {
              name
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 24
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              alternativeText
              id
            }
          }
          Panier {
            Image {
              name
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 24
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              alternativeText
              id
            }
          }
          Utilisateur {
            Image {
              name
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 24
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              alternativeText
              id
            }
          }
        }
      }
    }
    `
  )
  return data
}
