import React from "react"
import ReactMarkdown from "react-markdown"
import { Wrapper, Title, LogoWrapper, Logo, Link } from "./Column.style"
import { CommonWrapper } from "./../../CommonWrapper"
import { v4 as uuidv4 } from "uuid"
import { useDetectDevice } from "../../../../lib/useDetectDevice"

interface Props {
  data: Record<any, any>
  title: string
  children?: React.ReactChild
}

export function ColumnView({ data, title, children }: Props) {
  var components: any = []
  const isMobile = useDetectDevice()
  var imageComponents: any = []
  for (var i = 0; i < data.length; i++) {
    var item = data[i]
    // Text items
    if (item.hasOwnProperty("Text") || item.hasOwnProperty("LongText")) {
      var text = ""
      if (item.hasOwnProperty("Text")) text = item.Text
      if (item.hasOwnProperty("LongText")) text = item.LongText
      if (item.hasOwnProperty("Link") && item.Link != "" && item.Link != null) {
        components.push(
          <Link id={item.id} key={uuidv4()} link={"/" + item.Link}>
            <TextComponent text={text} />
          </Link>
        )
      } else {
        components.push(<TextComponent text={text} key={uuidv4()} />)
      }
    }
    // Markdown items
    else if (item.hasOwnProperty("RichText")) {
      if (item.hasOwnProperty("Link") && item.Link != "" && item.Link != null) {
        components.push(
          <Link id={item.id} key={uuidv4()} link={"/" + item.Link}>
            <ReactMarkdown>{item.RichText} </ReactMarkdown>
          </Link>
        )
      } else {
        components.push(
          <ReactMarkdown key={uuidv4()}>{item.RichText}</ReactMarkdown>
        )
      }
    }
    // Image items
    else if (item.hasOwnProperty("Image")) {
      // If there are plural images, I want to display them in a raw, so I create an intermediate component.
      for (var j = i; j < data.length; j++) {
        var imageItem = data[j]
        if (imageItem.hasOwnProperty("Image")) {
          if (imageItem.hasOwnProperty("Link") && imageItem.Link == "nous-contacter") {
            imageComponents.push(
              <Link id={item.id} key={uuidv4()} link={"/" + item.Link}>
                <Logo data={imageItem.Image} />
              </Link>
            )
          } else {
            imageComponents.push(<Logo key={uuidv4()} data={imageItem} />)
          }
        }
        // I don't want to parse item twice, so I set general index I to current loop index J
        i = j
        // If I reached the last item, I leave
        if (j + 1 >= data.length) {
          break
        }
        // If next item has no image, I leave
        if (!data[j + 1].hasOwnProperty("Image")) {
          break
        }
      }
      // I add all images
      components.push(
        <LogoWrapper key={uuidv4()}>
          {imageComponents.map(compo => compo)}
        </LogoWrapper>
      )
      imageComponents = []
    }
  }
  return (
    <CommonWrapper>
      <Wrapper className={isMobile && "mobile"}>
        <Title className={isMobile && "mobile"}>{title}</Title>
        {components.map(component => component)}
        {children}
      </Wrapper>
    </CommonWrapper>
  )
}

const TextComponent = ({ text, id }: any) => <div key={uuidv4()}>{text}</div>
