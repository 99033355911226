import React from "react"
import {
  StyledP,
  Domaine,
  EncartDomaine,
  ItemDomaine,
  SousDomaineWrapper,
  Div,
  Photo,
  SousDomaineItemsView,
  SousDomaineMarqueItemsView,
  EncartAllItems,
} from "./DomaineItem.style"
import { navigate } from "gatsby"

interface Props {
  className?: string
  data: Object
  isActive: boolean
  displayContent: boolean
}


export function DomaineItemView({
  className,
  data,
  isActive,
  displayContent,
}: Props) {

  const getMarqueItems = data => {
    return (
      <SousDomaineWrapper>
        <SousDomaineMarqueItemsView data={data.children} />
        {/* pas de place pour la photo sur le menu marque */}
        {/* {data.category?.backgroundImage?.url && (
          <EncartDomaine>
            <Photo src={data?.collection?.backgroundImage?.url} />
          </EncartDomaine>
        )} */}
      </SousDomaineWrapper>
    )
  }

  const getNonMarqueItems = data => {
    return (
      <SousDomaineWrapper>
        <SousDomaineItemsView data={data.children} />
        {data.category?.backgroundImage?.url && (
          <EncartDomaine>
            <Photo src={data.category?.backgroundImage?.url} />
          </EncartDomaine>
        )}
        {data.category?.slug && (
          <EncartAllItems onClick={e => { navigate("/" + data.category?.slug) }}>
            Tout voir
          </EncartAllItems>)
        }

      </SousDomaineWrapper>
    )
  }

  const getContent = data => {
    if (data.children) {
      if (data.name == "Marques") {
        return getMarqueItems(data)
      }
      return getNonMarqueItems(data)
    }
  }

  const hasNoChild = data.children?.length <= 0
  const slug = getSlug(data)
  return (
    <Div>
      <Domaine isActive={isActive}>
        <ItemDomaine
          hasNoChild={hasNoChild}
          isActive={isActive}
          onClick={e => {
            if (!slug || data.name === "Marques") return false;

            if (hasNoChild || displayContent) {
              navigate("/" + getSlug(data))
            }
          }}
        >
          <StyledP>{data.name}</StyledP>
        </ItemDomaine>
      </Domaine>
      {displayContent && !hasNoChild && getContent(data)}
    </Div>
  )
}


export const getSlug = (item) => {
  return item.category?.slug || item.collection?.slug || item.page?.slug

}