import React from "react"
import { NavBarDesktopView } from "./NavBarDesktop/NavBarDesktop.view"
import { NavBarMobileView } from "./NavBarMobile/NavBarMobile.view"
import { useDetectDevice } from "./../../../../lib/useDetectDevice"

export interface Props {
  className?: string
  setOpen: () => void
  data: Record<any, any>
}

export function NavBarView(props: Props) {
  var isMobile = useDetectDevice()
  return (
    <>
      {isMobile && <NavBarMobileView {...props} />}
      {!isMobile && <NavBarDesktopView {...props}></NavBarDesktopView>}
    </>
  )
}
