import React, { useState, useEffect, useRef } from "react"
import { v4 as uuidv4 } from "uuid"
import { Props } from "../NavBar.view"
import { DomaineItem } from "./DomaineItem"
import { Wrapper, Div } from "./NavBarDesktop.style"

export function NavBarDesktopView({ className, data }: Props) {
  const [activeTab, setActiveTab] = useState()
  const [displayTabItems, setDisplayTabItems] = useState()
  const myTimer = useRef(0)

  const menuRef = useRef()

  const displayMenu = (index) => {
    setActiveTab(index)
    setTimeout(() => {
      setDisplayTabItems(index)
    }, 150)
  }

  const hideMenu = () => {
    clearTimeout(myTimer.current)
    setActiveTab(null)
    setDisplayTabItems(null)
  }



  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick)
      clearTimeout(myTimer.current)
    }
  }, [])

  const handleClick = (e: Event) => {
    e.stopPropagation()
    if (menuRef.current?.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    hideMenu()
  }


  return (
    <Wrapper ref={menuRef} onMouseLeave={hideMenu} >
      {data &&
        data.map((item: any, index: number) => {
          // item.name == "Marques"
          return (
            <Div
              key={uuidv4()}
              onMouseEnter={() => {
                displayMenu(index)
              }}
              onClick={() => {
                displayMenu(index)
              }}
            >
              <DomaineItem
                data={item}
                isActive={activeTab == index}
                // activer le menu => /!\
                // displayContent={true}
                displayContent={displayTabItems == index}
              ></DomaineItem>
            </Div>
          )
        })}
    </Wrapper>
  )
}
