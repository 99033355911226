import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_BRAND_NAME } from "./DynamicBrandName.query";
import { getSpecificAttibuteByName } from "../../../helper/getSpecificAttibuteByName";
import { BrandName } from "./DynamicBrandName.style";

interface props {
    productId: string | undefined;
}
export const DynamicBrandNameView = ({ productId }: props) => {

    const [getProductBrand, dynamicGetProductBrand] = useLazyQuery(GET_BRAND_NAME);
    const [brandName, setBrandName] = useState("");

    useEffect(() => {
        if (dynamicGetProductBrand?.data) {

            const attributes = dynamicGetProductBrand?.data?.product?.attributes || [];
            const result = getSpecificAttibuteByName(attributes, "Marque");
            setBrandName(result);
        }
        if (dynamicGetProductBrand?.error) {
            setBrandName("");
        }
    }, [dynamicGetProductBrand]);

    //  recuperation une seul fois de la marque
    if (productId && dynamicGetProductBrand && !dynamicGetProductBrand.called && !dynamicGetProductBrand.loading) {
        getProductBrand({ variables: { id: productId } });
    }

    if (!productId || !brandName)
        return <></>;

    return <BrandName>{brandName}</BrandName>;

};
