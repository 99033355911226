import { useStaticQuery, graphql } from "gatsby"
export const FlashMessageQuery = () => {
  const data = useStaticQuery(
    graphql`
    query FlashMessageQuery {
      strapiGmMessageBox {
        Text
        BackgroundColor
        FontColor
      }
    }
    
  `
  )
  return data
}
