import React, { useContext, useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { navigate } from "gatsby"
import {
  Wrapper,
  Domaine,
  Categorie,
  ItemWrapper,
  SousCategorie,
  StyledLink,
  ItemDomaine,
  EncartAllItems,
  IconRight,
  LinkEmpty
} from "./DomaineItem.style"

interface Props {
  className?: string
  data: Object
  first: boolean
  last: boolean
}

export const OpenContext = React.createContext({ "isOpen": false, "setIsOpen": () => { } });

export function DomaineItemView({ className, first, last, data }: Props) {

  const [isOpen, setIsOpen] = useState(false)

  const handleToogle = (event: Event) => {
    event.stopPropagation();
    setIsOpen(isOpen => !isOpen)
  }

  return (
    <Wrapper className={className} first={first} last={last}>
      <OpenContext.Provider value={{ "isOpen": isOpen, "setIsOpen": setIsOpen }}>
        <Domaine onClick={handleToogle}>
          <DomaineButton data={data}></DomaineButton>
        </Domaine>
      </OpenContext.Provider>
    </Wrapper>
  )
}

const DomaineButton = ({ data }) => {

  const { isOpen, setIsOpen } = useContext(OpenContext)
  const handleNavigateClick = () => { setIsOpen(false); navigate("/" + data.slug) }

  if (!data.children.length) {
    return (

      <ItemDomaine onClick={() => { handleNavigateClick() }}>
        {data.name?.toUpperCase()}
      </ItemDomaine>
    )
  }
  return (
    <>
      <ItemDomaine >
        {data.name?.toUpperCase()}
        <ShowAllButton slug={data.slug} name={data.name}>
          {!isOpen ? (<IconRight icon="arrow_next" />) : (<IconRight icon="eye" />)}
        </ShowAllButton>
      </ItemDomaine>
      {isOpen && getItems(data)}
    </>)

}



const getItems = (data: any) => {
  if (data.children) {
    return (<ItemWrapper>
      {data.children.map(child => {
        const { slug } = child.category || child.collection || child.page || { slug: null }
        const { name, children } = child

        return (
          <Categorie key={uuidv4()}>
            {slug ? (<StyledLink link={"/" + slug}>
              {name}
            </StyledLink>) : (<LinkEmpty>{name} </LinkEmpty>)}

            {children.map((sousCategorie: any) => {
              const { slug } = sousCategorie.category || sousCategorie.collection || sousCategorie.collection || sousCategorie.page || { slug: null }
              const { name, children } = sousCategorie

              return (
                <SousCategorie key={uuidv4()}>
                  <StyledLink link={"/" + slug}>
                    {name}
                  </StyledLink>
                </SousCategorie>
              )
            })}
          </Categorie>
        )
      })
      }
    </ItemWrapper >)
  }
  return null
}

const ShowAllButton = ({ name, slug, children }: { name: string, slug: string }) => {
  const { isOpen, setIsOpen } = useContext(OpenContext);
  const handleClick = isOpen ? () => { setIsOpen(false); navigate("/" + slug) } : () => { }
  return (
    <EncartAllItems onClick={e => { handleClick() }}>
      {children}
    </EncartAllItems>
  )
}

