import { useStaticQuery, graphql } from "gatsby"

export const frag_MenuItem = graphql`  
fragment frag_NavItem on SALEOR_MenuItem {
  name
  level
  category {
    slug
  }
  collection {
    slug
  }
  page {
    slug
  }
}

`

export const useNavBarQuery = () => {
  const data = useStaticQuery(
    graphql`
    query GetMenuItems {
      saleor {
        menu(name: "navbar", channel: "default-channel") {
          items {
            ...frag_NavItem
            children {
              ...frag_NavItem
              children {
                ...frag_NavItem
              }
            }
            category {
              backgroundImage {
                url
                alt
              }
            }
            collection {
              backgroundImage {
                url
              }
            }
          }
        }
      }
    }
    
    `
  )
  return data
}
