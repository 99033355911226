import { gql } from "@apollo/client"

export const GET_BRAND_NAME = gql`
query getBrandName($id: ID) {
  product(id: $id) {
    attributes {
      attribute {
        name
      }
      values {
        name
      }
    }
  }
}
`
