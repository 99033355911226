import { AppActionTypes } from "../../store/actionTypes"
import {
  SET_SEARCHKEY_LIST,
  CLEAR_SEARCHKEY_LIST,
} from "./searchKey.actionTypes"
import { SearchKeyReducerState } from "./searchKey.types"

export function setSearchKeyList(
  keyword: SearchKeyReducerState
): AppActionTypes {
  return {
    type: SET_SEARCHKEY_LIST,
    keyword,
  }
}

export function clearSearchKey(): AppActionTypes {
  return {
    type: CLEAR_SEARCHKEY_LIST,
  }
}
