import { navigate } from "gatsby"
import { Media } from "gatsby-plugin-fresnel"
import React, { Fragment, useEffect, useRef, useState } from "react"
import reactDom from "react-dom"
import { useDispatch, useSelector } from "react-redux"
import { useFlexSearch } from "react-use-flexsearch"
import _ from "lodash"
import slugify from "slugify"
import { v4 as uuidv4 } from "uuid"
import { setHeaderStatus } from "../../../../entities/headerStatus/headerStatus.actions"
import {
  SearchResultItem,
  SearchResults,
} from "../../../../entities/Search/SearchResults"
import { gtagger } from "../../../../helper/gtag"
import { useClickedOutside } from "../../../../lib"
import { useDetectDevice } from "../../../../lib/useDetectDevice"
import { setSearchKeyList } from "./../../../../entities/searchKey/searchKey.actions"
import {
  CommonWrapperBar,
  Input,
  InputMobile,
  InputWrapper,
  ItemList,
  ItemWrapper,
  ProduitImage,
  ProduitName,
  Results,
  Search,
  SearchMobile,
  Wrapper,
  WrapperMobile,
  MobileResult,
} from "./SearchBar.style"

interface Props {
  className?: string
  onClickHandler: () => void
  placeholder?: string
  magnifyingGlass: Record<string, any>
  toggleSearchActive: Function
  index: any
  store: any
}

function SearchBarCore({
  className,
  onClickHandler,
  placeholder = "",
  magnifyingGlass,
  index,
  store,
}: Props) {
  const dispatch = useDispatch()

  const searchActive = useSelector(
    state => state.headerStatusReducer.searchActive
  )


  const [cursor, setCursor] = useState(0)

  const refInput = useRef()
  const resultsQuery = useRef([] as SearchResultItem[])
  const [queryText, setQueryText] = useState("")


  const handleKeyDown = (e: any) => {
    const input = e.target
    if (e.key === "ArrowDown") {
      input.selectionStart = input.value.length
      if (cursor == resultsQuery.current.length) {
        setCursor(0)
      } else {
        setCursor(cursor + 1)
      }
    } else if (e.key === "ArrowUp") {
      input.selectionStart = input.value.length
      if (cursor != 0) {
        setCursor(cursor - 1)
      }
    } else if (e.key === "Enter") {
      if (cursor != 0) {
        onSelectItem(resultsQuery.current[cursor - 1])
      } else {
        let keyword = slugify(refInput?.current?.value || "")
        dispatch(setSearchKeyList(keyword))
        searchActiveOFF()
        navigate("/recherche/?r=" + keyword)
      }
    }
  }

  // set les changement du texte de recherche sur le state de recherche queryText
  const handleChange = async (e: any) => {

    var text = e.target.value
    if (text.length >= 2) {
      setQueryText(text)
      searchActiveON()
    }


  }

  useEffect(() => {
    toggleInputDisplay(searchActive)
  }, [searchActive])

  // gere le focus + l'ouverture menu (en mobile) + le clean de l'input
  const toggleInputDisplay = (isOpen: boolean) => {
    var node: any = reactDom.findDOMNode(refInput?.current)
    if (!node) return false
    if (isOpen) {
      node.classList.add("btn-menu-open")
      node.focus()
    } else {
      node.classList.remove("btn-menu-open")
      node.value = ""
      resultsQuery.current = []
    }
  }

  const searchActiveON = () => {
    dispatch(setHeaderStatus({ searchActive: true }))
  }
  const searchActiveOFF = () => {
    dispatch(setHeaderStatus({ searchActive: false }))
  }

  const navigateHandler = (e) => {
    e.preventDefault()
    if (refInput.current?.value.length < 3) return false;

    let keyword = slugify(refInput.current.value)

    dispatch(setSearchKeyList(keyword))
    searchActiveOFF()
    navigate("/recherche/?r=" + keyword)
  }

  return (
    <CommonWrapperBar>
      <Media lessThan="md">
        <WrapperDetectClickOut
          callbackClickOut={() => {
            searchActiveOFF()
          }}
          callbackClickIn={onClickHandler}
        >
          <WrapperMobile className={className}>
            <SearchMobile data={magnifyingGlass} onClick={searchActiveON} />
            <InputWrapper>
              <InputMobile
                ref={refInput}
                autoFocus={searchActive}
                placeholder={placeholder}
                onChange={(e) => _.debounce(handleChange, 300)(e)}
                onFocus={() => { onClickHandler(); searchActiveON() }}
                onKeyUp={handleKeyDown}
              />
            </InputWrapper>
            {searchActive && index && queryText.length >= 3 && (
              <SearchResultMemo
                queryText={queryText} index={index} store={store} cursor={cursor}
              />
            )}
          </WrapperMobile>
        </WrapperDetectClickOut>
      </Media>
      <Media greaterThanOrEqual="md">
        <WrapperDetectClickOut
          callbackClickOut={() => {
            searchActiveOFF()
          }}
          callbackClickIn={() => { }}
        >
          <Wrapper className={className}>
            <InputWrapper>
              <Input
                autoFocus={searchActive}
                ref={refInput}
                placeholder={placeholder}
                onChange={(e) => _.debounce(handleChange, 300)(e)}
                onKeyUp={handleKeyDown}
                onFocus={() => { onClickHandler(); searchActiveON() }}
              />
              <Search
                data={magnifyingGlass}
                onClick={navigateHandler}
              />
            </InputWrapper>
            {searchActive && index && queryText.length >= 3 && (
              <SearchResultMemo
                queryText={queryText} index={index} store={store} cursor={cursor}
              />
            )}
          </Wrapper>
        </WrapperDetectClickOut>
      </Media>
    </CommonWrapperBar>
  )
}


const SearchResultMemo = React.memo(({ queryText, index, store, cursor }) => <SearchResult queryText={queryText} index={index} store={store} cursor={cursor} />)


const WrapperDetectClickOut = ({ children, callbackClickOut, callbackClickIn }) => {
  const { clickedOutside, setElementRef } = useClickedOutside()
  const dispatch = useDispatch()
  const searchActive = useSelector(
    state => state.headerStatusReducer.searchActive
  )

  useEffect(() => {
    if (clickedOutside && searchActive) {
      dispatch(setHeaderStatus({ searchActive: false }))
      callbackClickOut()
    }
  }, [clickedOutside, searchActive])

  const ref = setElementRef()

  return <div onClick={callbackClickIn} ref={ref}>{children}</div>
}

interface searchResultProp {
  resultsSearch: SearchResultItem[]
  cursor: number
}


const SearchResult = ({ cursor, queryText, index, store }: searchResultProp) => {

  const isMobile = useDetectDevice()
  const [resultsSearch, setResultsSearch] = useState([])
  const options = isMobile ? {} : { "limit": 5 }
  const customSearch = useFlexSearch(queryText, index, store, options)
  let timeout: any = null;
  const search = async () => {

    if (customSearch) {
      const result = await customSearch.map((r) => {
        const image = r.media
        const item: SearchResultItem = {
          Image: image,
          name: r.name,
          slug: r.slug,
          BrandName: r.brandName,
        }
        return item
      })
      const items = result
      if (items.length > 0) {
        setResultsSearch(items)
      }
      sendSearchToGtag(queryText)
    }

  }
  useEffect(() => {
    if (queryText.length >= 3) {

      search()
    }
  }, [queryText])

  const sendSearchToGtag = (queryText: string) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      gtagger("search", { "value": queryText })
    }, 5000)
  }
  if (!resultsSearch?.length) return <></>

  const clickMobileHandler = () => {
    const text = slugify(queryText || "")
    navigate("/recherche/?r=" + text, { replace: true })

  }

  return (
    <Results className={isMobile && "mobile"}>
      {isMobile ? <MobileResult onClick={() => clickMobileHandler()}>{resultsSearch.length} résultats</MobileResult> : resultsSearch?.map((item: SearchResultItem, index: number) => (
        <ItemSearchResult
          item={item}
          active={index + 1 == cursor}
          key={uuidv4()}
        ></ItemSearchResult>
      ))}


    </Results>
  )
}

const ItemSearchResult = ({ item, active }) => {
  let node = item as SearchResults

  const searchActive = useSelector(
    state => state.headerStatusReducer.searchActive
  )

  const dispatch = useDispatch()

  const onClick = () => {
    onSelectItem(item)
    dispatch(setHeaderStatus({ searchActive: false }))
  }
  if (!searchActive) {
    return <></>
  }
  return (
    <ItemList key={node.id} data-value={node}>
      <ItemWrapper onClick={onClick} onTouchStart={onClick}>
        <ProduitImage data={item}></ProduitImage>
        <ProduitName className={`${active ? "active" : ""}`}>
          {node.name}
          <br /> {node.BrandName}
        </ProduitName>
      </ItemWrapper>
    </ItemList>
  )
}

function onSelectItem(dataItem: SearchResultItem) {
  if (dataItem.BrandName && dataItem.slug) {
    const brandSlug = slugify(dataItem.BrandName, { lower: true }).replace("\'", "")
    navigate("/p/" + brandSlug + "/" + dataItem.slug)
  }

  return false
}


export const SearchBarView = React.memo(SearchBarCore)