import React from "react"
import { v4 as uuidv4 } from "uuid"
import { getSlug } from "../DomaineItem.view"
import {
  Item,
  SubItem,
  ItemSousCategorie,
  ItemWrapper,
  SousCategorie,
  StyledLink,
  StyledTitle
} from "./SousDomaineItems.style"

export const menuContext = React.createContext(false)

export function SousDomaineItemsView({ className, data }: Props) {

  return (
    <ItemWrapper className={className}>
      {data?.map(item => {
        const slug = getSlug(item)
        return (
          <SubItem key={uuidv4()}  >
            {slug ? (<StyledLink link={"/" + slug}><strong>{item.name}</strong></StyledLink>) :
              (<StyledTitle><strong>{item.name}</strong></StyledTitle>)}
            <SubCatergories items={item.children} />
          </SubItem>
        )
      })}
    </ItemWrapper>
  )
}

const SubCatergories = ({ items }) => {
  return (
    <>
      {items?.map((sousCategorie: any) => {
        const slug = getSlug(sousCategorie)
        return (
          <SousCategorie key={uuidv4()} >
            <ItemSousCategorie>
              {slug ? (<StyledLink link={"/" + slug}>{sousCategorie.name}</StyledLink>) :
                (<StyledTitle>{sousCategorie.name}</StyledTitle>)}
            </ItemSousCategorie>
          </SousCategorie>
        )
      })}
    </>)
}
