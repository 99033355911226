import React, { useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import { Props } from "../NavBar.view"
import { DomaineItem } from "./DomaineItem"
import { Wrapper, StyledMenu } from "./NavBarMobile.style"
import { useClickedOutside } from "../../../../../lib"

export function NavBarMobileView({ className, data, setOpen }: Props) {
  const { clickedOutside, setElementRef } = useClickedOutside()
  const ref = setElementRef()
  useEffect(() => {
    if (clickedOutside) {
      setOpen(false)
    }
  }, [clickedOutside])

  if (!data) return <></>
  return (

    <Wrapper className={className} ref={ref}>
      <StyledMenu>
        {data.map((item: any, index: number, array: Array<any>) => {
          var node = {}
          if (item.category) node = item.category
          if (item.collection) node = item.collection

          // 
          node = { ...node, "name": item.name, children: item.children }
          return (
            <DomaineItem
              first={index == 0}
              last={index >= array.length - 1}
              data={node}
              key={uuidv4()}
            />
          )
        })}
      </StyledMenu>
    </Wrapper>
  )
}
