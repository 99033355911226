
export function getSpecificAttibuteByName(
  attributes: Attribute[],
  AttibuteName: string
): string {
  const reducer = (accumulator: string, currentValue: Attribute) => {
    if (currentValue?.attribute?.name === AttibuteName) {
      accumulator = currentValue?.values[0]?.name
    }
    return accumulator
  }
  return attributes?.reduce(reducer, "")
}

interface Value {
  name: string
  value: string
}

export interface Attribute {
  attribute: { name: string }
  values: Value[]
}
