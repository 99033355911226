import { AppActionTypes } from "../../store/actionTypes"
import { SET_HEADERSTATUS } from "./headerStatus.actionTypes"
import { HeaderStatusReducerType } from "./headerStatus.types"

export function setHeaderStatus(data: HeaderStatusReducerType): AppActionTypes {
  return {
    type: SET_HEADERSTATUS,
    data,
  }
}
