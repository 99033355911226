import { useStaticQuery, graphql } from "gatsby"


export const useFooterQuery = () => {
  const data = useStaticQuery(
    graphql`query gmFooterFromSourceGraphql {
      strapiGmFooter {
        Colonne1Titre
        Colonne1 {
          ... on Node {
            ...dynamicImageLienComponent
            ...dynamicImageSimpleComponent
            ...dynamicFooterComponent
            ...dynamicWYSIWYGComponent
          }        
        }
        Colonne2Titre
        Colonne2 {
          ... on Node {
            ...dynamicImageLienComponent
            ...dynamicImageSimpleComponent
            ...dynamicFooterComponent
            ...dynamicWYSIWYGComponent
          }        
        }
        Colonne3Titre
        Colonne3 {
          ... on Node {
            ...dynamicImageLienComponent
            ...dynamicImageSimpleComponent
            ...dynamicFooterComponent
            ...dynamicWYSIWYGComponent
          }
        }
        Colonne4Titre
        Colonne4 {
          ... on Node {
            ...dynamicImageLienComponent
            ...dynamicImageSimpleComponent
            ...dynamicFooterComponent
            ...dynamicWYSIWYGComponent
          }
        }
        Colonne5Titre
        Colonne5 {
          ... on Node {
            ...dynamicImageLienComponent
            ...dynamicImageSimpleComponent
            ...dynamicFooterComponent
            ...dynamicWYSIWYGComponent
          }
        }
      }
    }
    
    fragment dynamicFooterComponent on STRAPI__COMPONENT_GLOBAL_FOOTER {
      Text
      Link
    }
    
    fragment dynamicImageSimpleComponent on STRAPI__COMPONENT_GLOBAL_IMAGE_SIMPLE {
      Image {
        url
        alternativeText
      }
    }
    
    fragment dynamicImageLienComponent on STRAPI__COMPONENT_GLOBAL_IMAGE_LIEN {
      Image {
        url
        alternativeText
      }
      Link
    }
    
    fragment dynamicWYSIWYGComponent on STRAPI__COMPONENT_GLOBAL_WYSIWYG {
      childStrapiComponentGlobalWysiwygRichtextTextnode {
        childMarkdownRemark {
          rawMarkdownBody
          html
          excerpt
        }
      }
    }
         
    `
  )
  return data
}
