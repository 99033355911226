import React from "react"
import { Media } from "gatsby-plugin-fresnel"

import {
  Wrapper,
  WrapperMobile,
  SmallText,
  BigText,
} from "./TextOverText.style"
import { useDetectDevice } from "../../../lib/useDetectDevice"

interface Props {
  small?: string
  large?: string
  smallSize?: number
  largeSize?: number
  className?: string
}

export function TextOverTextView({
  small = "default",
  large = "default",
  smallSize = undefined,
  largeSize = undefined,
  className,
}: Props) {
  const isMobile = useDetectDevice()
  return (
    <div className={className}>
      {/* <Media lessThan="lg"> */}
      <Wrapper>
        <SmallText size={smallSize ? smallSize : 1}>{small}</SmallText>
        <BigText
          size={largeSize ? largeSize : 3}
          className={isMobile && "mobile"}
        >
          {large}
        </BigText>
      </Wrapper>
      {/* </Media>
      <Media greaterThanOrEqual="lg">
        <Wrapper>
          <SmallText size={smallSize ? smallSize : 2.313}>{small}</SmallText>
          <BigText size={largeSize ? largeSize : 6} className={isMobile && "mobile"}>{large}</BigText>
        </Wrapper>
      </Media> */}
    </div>
  )
}
