import styled from "styled-components"
import { ImageWithLinkOrNot } from "./../../ImageWithLinkOrNot"
import { Link as LinkBase } from "./../../Link"

export const Link = styled(LinkBase)`
  justify-content: flex-start;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: auto;
  padding-left: 20%;
  flex-grow: 1;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 15px;
  padding: 5px;
  /* padding-bottom: 10%; */
  &.mobile {
    align-items: left;
    text-align: left;
    /* row-gap: 15px; */
    padding: 0;
  }
`

export const Title = styled.div`
  font-size: ${({ theme }) => theme.typography.subtitle.fontSize};
  font-weight: ${({ theme }) => theme.typography.subtitle.fontWeight};
  /* margin-bottom: 0.75em; */
  text-align: left;
`

export const LogoWrapper = styled.div`
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-top: 5px;
  justify-content: flex-start;
`

export const Logo = styled(ImageWithLinkOrNot)`
  max-height: 30px !important;
  width: auto !important;
  height: auto;
  object-fit: contain !important;
  padding: 2px;
`

export const StyledLink = styled.a`
  text-decoration: none;

  color: ${({ theme }) => theme.palette.primary.contrastText};
`
