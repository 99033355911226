
import styled from "styled-components"
export const WrapperMainComponent = styled.div`
  > * {
    max-width: 85em;
    margin: auto;
  }
  padding-left: 2em;
  padding-right: 2em;
  margin-top: 2em;
`