import React from "react"
import {
  Footer,
  WrapperFooter,
  Wrapper,
  Infos,
  Column,
  DivHtml,
} from "./Footer.style"
import { SubscriptionBox } from "./SubscriptionBox"
import { useDetectDevice } from "../../../lib/useDetectDevice"
import { InfoBar } from "../InfoBar"

interface Props {
  data: Record<any, any>
  className?: string
}

export function FooterView({ data, className }: Props) {
  const isMobile = useDetectDevice()
  let isHomePage = false
  if (window.location.pathname == "/") isHomePage = true

  let classMobile = (className && className) + " " + (isMobile && "mobile")
  let classHomePage = classMobile + " " + (isHomePage && "homepage")

  const dataFooter = data.strapiGmFooter.Colonne4[0].childStrapiComponentGlobalWysiwygRichtextTextnode.childMarkdownRemark.html

  if (isMobile)
    return (
      <Footer className={isHomePage ? "homepage" : ""}>
        <WrapperFooter>

          {isHomePage && (
            <SubscriptionBox news="10€ offerts pour toute inscription" />
          )}
          <Infos>
            <InfoBar />
          </Infos>
          <Wrapper className={classHomePage}>
            <Column
              data={data.strapiGmFooter.Colonne4}
              title={data.strapiGmFooter.Colonne4Titre}
            >
              <DivHtml dangerouslySetInnerHTML={{ __html: dataFooter }} />
            </Column>
            <Column
              data={data.strapiGmFooter.Colonne1}
              title={data.strapiGmFooter.Colonne1Titre}
            />
            <Column
              data={data.strapiGmFooter.Colonne3}
              title={data.strapiGmFooter.Colonne3Titre}
            />
            <Column
              data={data.strapiGmFooter.Colonne2}
              title={data.strapiGmFooter.Colonne2Titre}
            />
            {/* <Column
              data={data.strapiGmFooter.Colonne5}
              title={data.strapiGmFooter.Colonne5Titre}
            /> */}
          </Wrapper>
        </WrapperFooter>
      </Footer>
    )
  else
    return (
      <>
        <InfoBar />
        <Footer className={isHomePage ? "homepage" : ""}>
          <WrapperFooter>
            {isHomePage && (
              <SubscriptionBox news="10€ offerts pour toute inscription" />
            )}


            <Wrapper className={classHomePage}>
              <Column
                data={data.strapiGmFooter.Colonne1}
                title={data.strapiGmFooter.Colonne1Titre}
              />
              <Column
                data={data.strapiGmFooter.Colonne2}
                title={data.strapiGmFooter.Colonne2Titre}
              />
              <Column
                data={data.strapiGmFooter.Colonne3}
                title={data.strapiGmFooter.Colonne3Titre}
              />
              <Column
                data={data.strapiGmFooter.Colonne4}
                title={data.strapiGmFooter.Colonne4Titre}
              >
                <DivHtml dangerouslySetInnerHTML={{ __html: dataFooter }} />
              </Column>


              {/* </WrapperFooter> */}
              {/* <Column
                data={data.strapiGmFooter.Colonne5}
                title={data.strapiGmFooter.Colonne5Titre}
              /> */}
            </Wrapper>
          </WrapperFooter>
        </Footer>
      </>
    )
}
