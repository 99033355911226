import React, { useEffect, useState } from "react"

import {
  WrapperCounter,
  Button,
  Total,
  StyledCommonWrapper,
} from "./Counter.style"
import { useDetectDevice } from "../../../lib/useDetectDevice"

interface Props {
  className?: string
  onChange?: Function
  addOneHandler?: Function
  removeOneHandler?: Function
  value?: number
}

export function CounterView({
  onChange = () => {
    return null
  },
  addOneHandler = () => { },
  removeOneHandler = () => { },
  className,
  value,
}: Props) {
  const [count, setCount] =useState(value || 1)

  useEffect(() => {
    if (value !== null)
      setCount(value)

  }, [value])

  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")

  const removeOne = () => {
    if (count > 0) {
      var newValue = count - 1
      setCount(newValue)
      onChange(newValue)
      removeOneHandler()
    }
  }

  const addOne = () => {
    var newValue = count + 1
    setCount(newValue)
    onChange(newValue)
    addOneHandler()
  }

  return (
    <StyledCommonWrapper className={classNames}>
      <WrapperCounter className={classNames}>
        <Button disabled={count === 0 ? "disabled" : ""} onClick={removeOne}>
          -
        </Button>
        <Total>{count}</Total>
        <Button onClick={addOne}>+</Button>
      </WrapperCounter>
    </StyledCommonWrapper>
  )
}
