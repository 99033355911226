import React from "react";
import { gtagger } from "../../../helper/gtag";

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        //   logErrorToMyService(error, errorInfo);
        console.error("Error catch :", error, errorInfo)
        gtagger("Erreur dans la page", { "page": window?.location, "error": { error, errorInfo } })

    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            gtagger("Erreur dans la page", { "page": window?.location })
            return <>Erreur dans la page</>
        }

        return this.props.children;
    }
}