import styled from "styled-components"
import { ImageWithLinkOrNot } from "../ImageWithLinkOrNot"
import { Column as ColumnBase } from "./Column"
import { CommonWrapper } from "./../CommonWrapper"

export const Footer = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background-color: #fbfbfb;
`
export const WrapperFooter = styled(CommonWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, 0.8fr));
  grid-gap: 10px;
  padding: 2em 3em;
  align-items: start;
  justify-items: center;

  &.homepage {
    // a remettre pour la mailing list
    /* padding-top: 150px; */
  }
  &.mobile {
    padding: 3em 1em;
    margin-top: auto;
    grid-template-columns: 1fr 1fr;
  }
`

export const ColumnWrapperMobile = styled.div`
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  padding-top: 5%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  justify-items: center;
  padding-bottom: 3%;
`
export const Column = styled(ColumnBase)`
  width: initial;
`

export const Infos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  /* margin-top: 10px; */
  width: 100%;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 50%; */
  align-items: center;
  margin-top: 0;
`

export const Text = styled.div`
  height: 5em;
  text-align: center;
  margin-top: 10px;
`

export const Img = styled(ImageWithLinkOrNot)`
  width: 60px;
`

export const DivHtml = styled.div`
  /* padding: 5px;
  font-size: 15px; */
  a:link {
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
  h4{
    font-weight : bold;
    font-size: 22.5px;
  }
  p {
    margin-top: 0px;
  }
`
