import styled from "styled-components"
import { Button } from "../../../../Button"
import { Item, SubItem } from "./SousDomaineItems/SousDomaineItems.style"
import { SousDomaineItemsView as SousDomaineItemsViewBase } from "./SousDomaineItems/SousDomaineItems.view"

export const Div = styled.div``

export const ItemBase = styled.div`
  border: none;
  margin: 0;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  transition-timing-function: ease-in;
  text-decoration: none;
  font-family: "Ebrima", Arial;
  font-style: normal;
  font-weight: 400;
  text-align: left;
`
export const Domaine = styled(ItemBase)``

export const SousDomaineWrapper = styled(ItemBase)`
  position: absolute;
  width: 70%;
  left: 15%;
  box-shadow: 1px 6px 35px -12px #000000;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-auto-rows: minmax(100px, auto);
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.info.contrastText};
  margin-top: -1px !important;
  justify-items: start;
  align-items: start;
  // ici pour fixer la taille max du contenu /!\
  /* overflow: hidden;
  max-height: 100px; */
`

export const SousDomaineItemsView = styled(SousDomaineItemsViewBase)`
  max-width: 100%;
`
export const SousDomaineMarqueItemsView = styled(SousDomaineItemsViewBase)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  ${SubItem} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    div:first-child {
      text-align: center;
      grid-column: 1 / 3;
    }
  }
`

export const EncartDomaine = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  opacity: 1;
  margin-bottom: auto;
`

export const Photo = styled.img`
  height: 100.5%;
  width: 100%;
  object-fit: contain;
  /* margin-top: -2px; */
`

const withoutCategory = theme => {
  return `${StyledP} {
    font-weight: normal;
    font-style: italic;
  }
  // background-color: ${theme.palette.error.main};
   `
}

export const StyledP = styled.p`
  justify-content: flex-start;
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: 100%;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-weight: bold;
`

export const ItemDomaine = styled(Item)`
  display: flex;
  box-sizing: border-box;
  padding: 0 1em;
  align-items: center;
  justify-content: center;
  z-index: 0;
  ${({ isActive }) =>
    isActive ? "border-top: 2px inset rgba(102,101,99,0.19)" : ""};

  ${({ isActive }) => (isActive ? "margin-bottom:-2px" : "")};

  ${({ hasNoChild, theme }) => (hasNoChild ? withoutCategory(theme) : "")}
`

export const ItemSousCategorie = styled(Item)`
  padding-left: 1rem;
`

export const Categorie = styled(ItemBase)`
  color: #bd9d6d;
  text-decoration: none;
  font-family: "Ebrima", Arial;
  font-size: 10pt;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
`
export const GroupEncartDomaine = styled.div`
  display: flex;
  flex-direction: row;
`

export const SousDomaineGroupWrapper = styled.div``
export const EncartAllItems = styled(Button)`
  font-size: 20px;
  background-color: ${({ theme }) => theme.palette.background.gray};
  border: ${({ theme }) => theme.palette.background.gray};
  color: ${({ theme }) => theme.palette.button.main};
  margin-left: 54%;
  width: 200px;
`
