import styled from "styled-components"
import { Counter as CounterBase } from "../../../common/Counter"
import { CommonParagraph } from "../../../common/Texts"
import { Button as ButtonBase } from "../../../common/Button"
import { Link as LinkBase } from "../../../common/Link"
import { ImageWithLinkOrNot } from "../../../common/ImageWithLinkOrNot"
import { ButtonStyled } from "../../../common/ImageWithLinkOrNot/ImageWithLinkOrNot.style"

export const Wrapper = styled.div`
  padding: 0 5px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  color: ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
  &.mobile {
    margin-bottom: 30px;
    padding: 0 20px;
  }
`
export const SelectorsWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid #d2d2d2;
  :first-child {
    border-top: 1px solid #d2d2d2;
  }
  &.mobile{
    :first-child {
      border-top: none;
    }
  }
`

export const Top = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  box-sizing: border-box;
`

export const WrapperComposable = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
padding-bottom: 50px;
&.mobile {
  padding-bottom: 20px;
}
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Separation = styled.div`
  margin-bottom: 1em;
`

export const GridChoiceProduct = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const GridChoice = styled.div`
  width: 100%;
  display: flex;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.h1.fontSize} !important;
  flex-direction: column;
`

export const Text = styled(CommonParagraph)`
  margin-top: 15px;
  &.mobile{
    margin-bottom: 0;
  }
`
export const TextSizeGuide = styled.div`
  display: flex;
  &.mobile {
    flex-direction: column;
  }
`
export const TextError = styled(Text)`
  color: red;
`
export const TextCounter = styled(Text)`
  font-size: 18px;
`
export const TextExp = styled(Text)`
  font-weight: bold;
`

export const Select = styled.select`
  margin-left: 2em;
  width: 150px;
  cursor: pointer;
  border: 1px solid #9e9e9e;
  padding: 5px 0.5rem;
  border-radius: 50px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  &.mobile {
    font-size: 14px;
  }
`

export const PriceArea = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;
`

export const Price = styled.div`
  font-size: 32px;
  font-weight: 600;
  color: ${({ onSale }) =>
    onSale
      ? ({ theme }) => theme.palette.primary.main
      : ({ theme }) => theme.palette.secondary.main};
`

export const Promotion = styled(Price)`
  text-decoration-line: line-through;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 26px;
  font-weight: 400;
`

export const RowDC = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  &.mobile {
    display: none;
  }
`

export const Link = styled(LinkBase)`
  width: auto;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
`

export const Line = styled.hr`
  height: 1px;
  background: ${({ theme }) => theme.palette.common.black};
`
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  &.mobile {
    padding-top: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const ImageLogo = styled(ImageWithLinkOrNot)`
  object-fit: contain;
  height: 100px;
  width: fit-content;
  display: flex;
  justify-content: start;
`

export const ProductRow = styled.div`
  & h1 {
    font: normal normal 600 30px/22px Cormorant Garamond;
  }
  &.mobile {
    display: flex;
    text-align: center;
    margin: 0;
    padding: 0;
  }
`

export const Amount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  &.mobile {
    width: fit-content;
    padding-bottom: 5px;
  }
`

export const Counter = styled(CounterBase)`
  margin-left: 0.5em;
  border-radius: 5px;
`

export const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
`
export const Button = styled(ButtonBase)`
  padding: 0.75em 20px;
  margin: 0;
  &.mobile{
    margin: 20px auto;

  }
`
export const TextSelect = styled(Text)`
  margin-top: 5%;
  font-weight: bold;
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2em 1rem;
  box-shadow: 1px 1px 1px 1px
    ${({ theme }) => theme.palette.primary.contrastText};
  cursor: pointer;
`

export const Paragraph = styled.div`
  font: normal normal 300 16px/24px Poppins;
  letter-spacing: -0.4px;
  margin: 0 0 15px 0;
`

export const Alerte = styled.div`
  font: italic normal 300 16px/24px Poppins;
  background-color : ${({ theme }) => theme.palette.secondary.main};
  color: white;
  letter-spacing: -0.4px;
  margin: 0 0 15px 0;
  text-align: center;
`

export const QuantityMaxMessage = styled(Text)`
  color: red;
  text-align: center;
`