import styled from "styled-components"
import { Button } from "../../Button"

export const Wrapper = styled.div`
  z-index: 100;
  left: 1.5%;
  position: absolute;
  bottom: 20%;
`
export const ChildWrapper = styled.div`
  border: solid grey 1px;
  background-color: ${({ theme }) => theme.palette.background.default};
  height: 15% !important;
  width: 96.5%;
  position: fixed;
`

export const Texte = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  margin-left: 0.5%;
  margin-top: 0.5%;
  text-align: left;
  font-size: 1em;
  color: ${({ theme }) => theme.palette.text.primary};
`

export const CookieButton = styled(Button)`
  font-size: 1em;
  cursor: pointer;
  width: 28%;
  margin-top: 0.6%;
  margin-left: 2vh;
  border-radius: 0px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

export const WrapperCookie = styled.div`
  width: 50%;
  left: 25%;
  &.mobile{
    width:100%;
    left: 0;
  }
`
