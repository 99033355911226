import React from "react"
import {
  Wrapper,
  WrapperInfos,
  ItemImage,
  ProductName,
  VariantName,
  Column,
  PriceDetails,
  OldPrice,
  DiscountPrice,
  OriginalPrice,
  TotalPrice,
  BinPosition,
  ColumnProd,
  Alerte
} from "./CartItem.style"
import { Icon } from "../../../common/Icon"
import { Counter } from "../../../common/Counter"
import { fixPrice } from "../../../../helper/fixedPrice"
import { Spinner } from "../../../common/Spinner"
import { DynamicBrandName } from "../../../common/DynamicBrandName"
import { useCartWithTotal } from "../../../../hooks/useCartWithTotal"

interface IPricing {
  onSale: boolean
  priceUndiscounted: {
    currency: string
    net: {
      amount: number
    }
    gross: {
      amount: number
    }
  }
  price: {
    currency: string
    net: {
      amount: number
    }
    gross: {
      amount: number
    }
  }
}

export interface IVariant {
  id: string
  name: string
  product: Record<string, any>
  media: Record<string, any>[]
  pricing: IPricing
  className?: string
}

export interface CIProps {
  quantity: number
  variant: IVariant
  className?: string
}

const getPricingNode = (
  { onSale, priceUndiscounted, price }: IPricing,
  quantity: number
) => {

  if (onSale) {
    return (
      <PriceDetails>
        <OldPrice>
          {fixPrice((quantity * 100 * (priceUndiscounted.gross.amount)) / 100)}
          {price.currency}
        </OldPrice>
        <DiscountPrice>
          {fixPrice((quantity * 100 * (price.gross.amount)) / 100)}
          {priceUndiscounted.currency}
        </DiscountPrice>
      </PriceDetails>
    )
  }
  return (
    <OriginalPrice>
      {fixPrice((quantity * 100 * price.gross.amount) / 100)}{" "}
      {price.currency === "Eur" ? "€" : price.currency}
    </OriginalPrice>
  )
}

export function CartItemView({ quantity, variant, className = "" }: CIProps) {
  const { loaded, addItem, subtractItem, removeItem } =useCartWithTotal()

  let isOnAlert = false
  let manyAvailable = false
  if (variant.quantityAvailable < 10) {
    isOnAlert = true
    if (variant.quantityAvailable > 1) {
      manyAvailable = true
    }
  }

  const handleDelete = async () => {
    await removeItem(variant.id)
  }

  const addOneHandler = async (variantId) => {
    var diff = quantity - variant.quantityAvailable

    if (diff >= 0) {
      await addItem(variantId, 0)
    }
    else{
      await addItem(variantId, 1)
    }

  }

  const removeOneHandler = async (variantId) => {
    await subtractItem(variantId)
  }


  if (!loaded || !variant?.product) return <Spinner />

  return (
    <Wrapper>
      <WrapperInfos className={className}>
        <BinPosition>
          <Icon icon="trash-o" size={20} onClick={handleDelete} />
        </BinPosition>
        <Column>
          <ItemImage data={variant?.product?.thumbnail} />
        </Column>
        <ColumnProd>
          <DynamicBrandName productId={variant.product.id} ></DynamicBrandName>
          <ProductName>{variant.product.name}</ProductName>
          <VariantName>{variant.name}</VariantName>
        </ColumnProd>
        <Column>
          <Counter
            value={quantity}
            addOneHandler={() => addOneHandler(variant.id, 1)}
            removeOneHandler={() => removeOneHandler(variant.id)}
            />
          <TotalPrice>
            {getPricingNode(variant.pricing, quantity)}
          </TotalPrice>
        </Column>
      </WrapperInfos>
      {isOnAlert && (
        <>
          {manyAvailable &&
            <Alerte>
            Plus que {variant.quantityAvailable} articles disponibles !
          </Alerte>
          }
          {!manyAvailable &&
            <Alerte>
            Plus que {variant.quantityAvailable} article disponible !
          </Alerte>
          }
        </>
      )}   
    </Wrapper>
  )
}

