import styled from "styled-components"

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.primaryLight};
  left: 0;
  display: flex;
  width: 100%;
`

export const StyledMenu = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  width: 100%;
  text-align: left;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
  background: ${({ theme }) => theme.primaryLight};
`
