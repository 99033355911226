import { Link } from "./../../../../../Link/"
import styled from "styled-components"
import { ItemBase } from "../../../NavBarMobile/DomaineItem/DomaineItem.style"

export const SousCategorie = styled(ItemBase)`
  font-size: 10pt;
  color: #333333;
  font-weight: lighter;
`
export const Item = styled.div`
  font-size: 12pt;
  text-align: left;
  padding: 0.2rem;
`
export const SubItem = styled.div`
  font-size: 12pt;
  text-align: left;
  padding: 0.2rem;
	display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;

/*  todo :  etudier la possibilté de plusieur colonne pour les marque ...
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto; */

`
export const StyledLink = styled(Link)`
  padding-left: 1rem;
  justify-content: flex-start;
  &:hover {
    background-color: #f1f1f1;
  }
`
export const StyledTitle = styled.div`
  padding-left: 1rem;
  justify-content: flex-start;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  cursor: initial;
`

export const ItemSousCategorie = styled(Item)`
  padding-left: 1rem;
  margin-top: -0.15em;
  & ${StyledLink} {
    color: #333333;
  }
  overflow: hidden;
white-space: nowrap;

`
export const ItemWrapper = styled.div`
  height: fit-content;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem;
`
