import styled from "styled-components"

export const Button = styled.button`
  width: fit-content;
  padding: 0.75em 2em;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.palette.button.main};
  color: ${({ theme }) => theme.palette.button.contrastText};
  font-family: ${({ theme }) => theme.typography.button.fontFamily};
  font-weight: ${({ theme }) => theme.typography.button.fontWeight};
  font-size: ${({ theme }) => theme.typography.button.fontSize};
  line-height: ${({ theme }) => theme.typography.button.lineHeight};
  cursor: pointer;
  border: none;
`
