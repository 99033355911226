import { useCart, useAuth } from "@saleor/sdk";
import { useMemo, useEffect } from "react"
import { ProductQuantity } from "../entities/cart/cart.types"

export interface ITotal {
  // used here as final price, on sale or not
  currency: string
  gross: { amount: number }
  tax: { amount: number }
  net: { amount: number }
}

const timeoutPromise = (asyncfn, ms) => {
  return new Promise(resolve => setTimeout(() => { asyncfn().then((result) => resolve(result)) }, ms));
}

export function useCartWithTotal() {
  const { items, loaded, totalPrice, subtotalPrice, shippingPrice, discount, addItem, removeItem, subtractItem, updateItem } = useCart()
  const { authenticated } = useAuth()

  const numberOfItems = items ? calcularteNumberOfItems(items) : 0
  const totalPricing = items ? calculateTotalPricing(items) : 0


  useEffect(() => {
    if (!authenticated) {
      // Saleor SDK use localStorage to save cart before sync with saleor backend, I want to retrieve items when user sign in if he doesn't have a cart on backend
      if (localStorage.getItem('data_checkout')) {
        let data_checkout = localStorage.getItem('data_checkout')
        if (data_checkout) {
          localStorage.setItem('data_checkout_tmp', data_checkout)
        }
      }
    }
  }, [items])




  const addItemAsync = async (items: { variantId: string, quantity: Number }[]) => {
    let timer = 0
    const result = items.map(async (item) => {
      timer += 500
      if (item.variantId && item.quantity) {
        return timeoutPromise(async () => {
          return await addItem(item.variantId, item.quantity)
        }, timer)
      }
    })
    await Promise.all(result)
    return true
  }
  const addItemSync = async (items: { variantId: string, quantity: Number }[]) => {
    const result = items.map(async (item) => {
      if (item.variantId && item.quantity) {
        return await addItem(item.variantId, item.quantity)
      }
    })
    await Promise.all(result)
    return true
  }

  const addItems = authenticated ? addItemAsync : addItemSync



  return useMemo(() => {
    return {
      loaded,
      numberOfItems: numberOfItems || 0,
      totalPricing: totalPricing || 0,
      subtotalPrice,
      addItems,
      // "items": items, totalPrice, shippingPrice, discount, addItem, removeItem, subtractItem, updateItem
      "items": (loaded ? items : []), totalPrice, shippingPrice, discount, addItem, removeItem, subtractItem, updateItem

    }
  }, [loaded, items]);
}


function addPriceToTotal(totalPrice, itemPrice, quantity) {
  const result = { ...totalPrice }
  // Attention impossible de melanger les type de currency sur un panier
  result.currency = itemPrice.gross.currency || itemPrice.net.currency
  if (result.currency == "Eur") result.currency = "€"

  result.gross.amount += itemPrice.gross.amount * quantity

  result.net.amount += itemPrice.net.amount * quantity

  return result
}

function calculateTotalPricing(items: ProductQuantity[]) {
  var totalCurrent: ITotal = {
    // used here as final price, on sale or not
    currency: "EUR",
    gross: { amount: 0 },
    tax: { amount: 0 },
    net: { amount: 0 },
  }
  var totalOriginal: ITotal = {
    // used here as original price, on sale or not
    currency: "EUR",
    gross: { amount: 0 },
    tax: { amount: 0 },
    net: { amount: 0 },
  }

  // const total = { totalDiscount, totalOriginal }
  var onSale = false

  const reducerTotalPrice = (accumulator, currentValue) => {
    if (currentValue?.variant?.pricing) {
      accumulator.totalOriginal = addPriceToTotal(
        accumulator.totalOriginal,
        currentValue.variant.pricing.priceUndiscounted,
        currentValue.quantity
      )

      accumulator.totalCurrent = addPriceToTotal(
        accumulator.totalCurrent,
        currentValue.variant.pricing.price,
        currentValue.quantity
      )
    }

    return accumulator
  }

  const result: { totalCurrent: ITotal; totalOriginal: ITotal } = items.reduce(
    reducerTotalPrice,
    {
      totalCurrent,
      totalOriginal,
    }
  )

  return {
    onSale: onSale,
    totalCurrent: result.totalCurrent,
    totalOriginal: result.totalOriginal,
  }
}

function calcularteNumberOfItems(items: ProductQuantity[]) {
  let count = 0
  items.map(item => {
    count += item.quantity
  })
  return count
}
