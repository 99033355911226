import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  box-sizing: content-box;
  margin-bottom: 1rem;
`

export const SmallText = styled.div`
  position: absolute;
  font-size: ${({ size }) => size}rem;
  z-index: 2;
  font-weight: bold;

  color: ${({ theme }) => theme.palette.text.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};
`

export const BigText = styled.div`
  font-size: calc(${({ size }) => size}rem + 1rem);
  line-height: ${({ size }) => size}rem;
  z-index: 1;
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  opacity: 0.4;
  &.mobile {
    font-size: calc(${({ size }) => size}rem);
  }
`
