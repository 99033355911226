import styled from "styled-components"
import { Button } from "../../common/Button"
import { CartItem } from "./CartItem"
import { CommonWrapper } from "../../common/CommonWrapper"
import { Wrapper as WrapperapperItem } from "./CartItem/CartItem.style"

export const CommonWrapperCart = styled(CommonWrapper)`
  height: 100%;
`
export const Wrapper = styled.div`
  height: 100%;
  padding-left: 20px;
  padding-right: 2px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  box-sizing: border-box;
  &.mobile {
    padding-left: 0px;
  }
`

export const Pricing = styled.div`
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-top: 2px solid ${({ theme }) => theme.palette.primary.contrastText};
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
`

export const Destination = styled.div`
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  padding: 20px;
  display: flex;
  justify-content: space-around;
  margin-top: 2%;
  margin-bottom: 10%;
`

export const Text = styled.div`
  font-weight: normal;
  font-size: ${({ theme }) => theme.typography.body.fontSize};
`

export const Div = styled.div`
  max-height: 50%;
  overflow: auto;
  &.mobile {
    ${WrapperapperItem} {
      box-shadow: ${({ theme }) => theme.shadows[1]};
    }
  }
`

export const P3 = styled.div`
  font-size: ${({ theme }) => theme.typography.body.fontSize};
`

export const Price = styled.div`
  font-size: ${({ theme }) => theme.typography.body.fontSize};
`

export const PriceBold = styled(Price)`
  font-size: ${({ theme }) => theme.typography.body.info};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`

export const Row = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1%;
  margin-left: -1em;
`

export const CloseCross = styled(Row)`
  height: 1px;
  display: flex;
  justify-content: center;
  cursor: pointer;
`

export const Cross = styled.div`
  font-size: 10px;
  padding-right: 1em;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-weight: ${({ theme }) => theme.typography.body.fontWeight};
  color: ${({ theme }) => theme.palette.primary.contrastText};
`

export const TextBold = styled.div`
  font-size: ${({ theme }) => theme.typography.body.info};
  display: flex;
  flex-direction: column;
  font-weight: ${({ theme }) => theme.typography.h1.fontWeight};
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
`

export const TopTextBold = styled.div`
  font-size: ${({ theme }) => theme.typography.body.info};
  display: flex;
  flex-direction: column;
  font-weight: bold;
  padding-left: 2em;
`

export const SelectCountry = styled.select`
  width: 10em;
  height: 2em;
`

export const BuyButton = styled(Button)`
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
`

export const CartItemS = styled(CartItem)``

export const RowDown = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1%;
  margin-top: 2em;
  padding-top: 1em;
  margin-left: -1.1em;
  margin-right: -1.1em;
  margin-bottom: 2em;
  box-sizing: border-box;
  font-size: ${({ theme }) => theme.typography.body.info};
  border-top: 2px solid ${({ theme }) => theme.palette.primary.contrastText};
`
