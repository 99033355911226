import styled from "styled-components"
import { Button } from "../../../../Button"
import { Icon } from "../../../../Icon"
import { Link as LinkBase } from "../../../../Link"

export const StyledLink = styled(LinkBase)`
  padding-left: 0.7rem;
  font-size: 12pt;
  text-align: left;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  justify-content: flex-start;
`

export const LinkEmpty = styled(Button)`
  margin-left: auto;
  margin-right: 1em;
  font-size: 0.6em;
  border: transparent;
  background-color: transparent;
  padding-left: 0.7rem;
  font-size: 12pt;
  text-align: left;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  justify-content: flex-start;
  color: #bd9d6d;
`

const firstItem = theme => {
  return `${ItemDomaine} {
    color: white;
  }
  background-color: ${theme.palette.error.main};
   `
}

const lastItem = theme => {
  return `${ItemDomaine} {
    color: white;
  } 
  background-color: ${theme.palette.secondary.main};
  `
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  /* ${({ first, theme }) => (first ? firstItem(theme) : "")}
  ${({ last, theme }) => (last ? lastItem(theme) : "")} */
`

export const Link = styled(LinkBase)``

export const EncartAllItems = styled(Button)`
  margin-left: auto;
  margin-right: 1em;
  font-size: 0.6em;
  border: transparent;
  background-color: transparent;
`
export const IconRight = styled(Icon)`
  margin-left: auto;
`

export const ItemBase = styled.div`
  border: none;
  margin: 0;
  & ${StyledLink} {
    text-decoration: none;
    background: transparent;
    cursor: pointer;
    transition-timing-function: ease-in;
    text-decoration: none;
    font-family: "Ebrima", Arial;
    font-style: normal;
    font-weight: 400;
    text-align: left;
  }
  &:focus {
    border: solid;
    border: transparent;
  }
  &:focus::after {
    background: transparent;
    transform: scale(2);
  }

  &:active {
    transform: scale(0.99);
  }
`

export const Domaine = styled(ItemBase)`
  width: 100%;
  border-top: 1.2px solid;
  border-bottom: 1.2px solid;
  padding: 1rem 0;
`

export const ItemDomaine = styled(ItemBase)`
  display: flex;
  justify-content: space-between;
  padding-left: 0.5rem;
  font-family: "Ebrima", Arial;
  color: ${({ theme }) => theme.palette.primary.contrastText};
`

export const ItemWrapper = styled.div`
  background-color: #efefef;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
`

export const Categorie = styled(ItemBase)`
  width: 100%;
  & ${StyledLink} {
    color: #bd9d6d;
    text-decoration: none;
    font-family: "Ebrima", Arial;
    font-size: 10pt;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    text-transform: uppercase;
  }
`

export const SousCategorie = styled(ItemBase)`
  text-decoration: none;
  font-family: "Ebrima", Arial;
  font-size: 10pt;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  text-align: left;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin-left: 1rem;
  & ${StyledLink} {
    color: #333333;
  }
`
