import { navigate } from "gatsby"
import { Media } from "gatsby-plugin-fresnel"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setHeaderStatus } from "../../../entities/headerStatus/headerStatus.actions"
import { useDetectDevice } from "../../../lib/useDetectDevice"
import { enumFrom } from "../ImageWithLinkOrNot"
import { FlashMessage } from "./../Layout/FlashMessage"
import {
  Cart, CommonWrapperHeader, Flex, Glissement, IconRight, Icons, QuestionRespons, InfoBar, SubTitle, Left, Logo, LogoMobile,
  MenuPopin, Middle, MiddleShrinked, NavBar, PastilleCompteur, PastilleCompteurMobile, Popin, Raw, RawSmall, Right, RightMobile, SearchBar,
  SearchBarMobile, StyledIcon, Tel, Title, Wrapper,
  WrapperMobile,
  WrapperShrinked, Bold
} from "./Header.style"
import { ShrinkManager } from "../ShrinkManager"
import { useCartWithTotal } from "../../../hooks/useCartWithTotal"

interface Props {
  data: Record<string, any>
}

function HeaderViewCore({ data }: Props) {
  const dispatch = useDispatch()
  const isMobile = useDetectDevice()

  const strapiGmHeader = data.strapiGmHeader
  const searchActive = useSelector(
    state => state.headerStatusReducer.searchActive
  )

  const [isMenuOpen, setOpen] = useState(false)
  const [firstMenuRender, setFirstMenuRender] = useState(true)

  const cartOpened = useSelector(state => state.headerStatusReducer.cartOpened)
  const setCartOpen = value => {
    dispatch(setHeaderStatus({ cartOpened: value }))
  }

  const [firstRender, setFirstRender] = useState(true)
  const { items } = useCartWithTotal()
  const numberOfItems = items?.length || 0

  useEffect(() => {
    if (isMenuOpen) setFirstMenuRender(false)
    if (cartOpened) setFirstRender(false)

    managePageScroll()
  }, [isMenuOpen, cartOpened])

  // At first render I don't want to add any class to popin component
  const getPopinClassName = () => {
    if (firstRender) {
      return ""
    } else {
      if (cartOpened) return "open"
      else return "close"
    }
  }
  // desactive le scroll de la page quand le panier est ouver pour eviter le double scroll panier /page 
  const managePageScroll = () => {
    if (cartOpened) { document.body.style.overflow = 'hidden'; }
    else { document.body.style.overflow = 'visible'; }
  }

  const getMenuPopinClassName = () => {
    if (firstMenuRender) {
      return ""
    } else {
      if (isMenuOpen) return "open"
      else return "close"
    }
  }

  const HeaderMobile = () => {
    return (
      <WrapperMobile>
        <StyledIcon
          data={strapiGmHeader.HamburgerMobile}
          from={enumFrom.STRAPI}
          onClick={() => {
            setOpen(true)
          }}
        />
        <MenuPopin
          className={getMenuPopinClassName()}
          onClickOutside={() => setOpen(false)}
          title="Menu"
          width="80%"
          left="0"
          minWidth="250px"
          height="100%"
        >
          <NavBar setOpen={setOpen} />
        </MenuPopin>
        <SearchBarMobile
          placeholder="Je recherche un produit"
          magnifyingGlass={strapiGmHeader.BandeauHeader.MoteurRecherche}
          open={searchActive}
        />
        {!searchActive && (
          <LogoMobile
            onClick={() => navigate("/")}
            data={strapiGmHeader.BandeauHeader.Logo}
            from={enumFrom.STRAPI}
          />
        )}
        <RightMobile className={isMobile && "mobile"}>
          <IconRight
            onClick={() => {
              navigate("/app/profile")
            }}
            data={strapiGmHeader.BandeauHeader.Utilisateur}
            from={enumFrom.STRAPI}
          />
          <StyledIcon
            data={strapiGmHeader.BandeauHeader.Panier}
            from={enumFrom.STRAPI}
            className={getPopinClassName()}
            onClick={() => {
              setCartOpen(!cartOpened)
            }}
          />
        </RightMobile>
      </WrapperMobile>
    )
  }


  const HeaderDesktop = () => {
    return <Wrapper>
      <FlashMessage />
      <Raw>
        <Left>
          <Logo
            onClick={() => navigate("/")}
            data={strapiGmHeader.BandeauHeader.Logo}
            from={enumFrom.STRAPI}
          />
          <SubTitle>Le linge de maison</SubTitle>

        </Left>
        <Middle>
          <SearchBar
            placeholder="Je recherche un produit"
            magnifyingGlass={strapiGmHeader.BandeauHeader.MoteurRecherche}
            onSelectItem={() => {
              navigate("/app/profile")
            }}
          />
        </Middle>
        <Right>
          <RightPart strapiGmHeader={strapiGmHeader} />
        </Right>
      </Raw>
      <NavBar setOpen={setOpen} className={getPopinClassName()} />
    </Wrapper>
  }

  const HeaderDesktopShrunked = () => {
    return <WrapperShrinked>
      <RawSmall>
        <Left>
          <Logo
            onClick={() => navigate("/")}
            data={strapiGmHeader.BandeauHeader.Logo}
            from={enumFrom.STRAPI}
          />
        </Left>
        <MiddleShrinked>
          <SearchBar
            placeholder="Je recherche un produit"
            magnifyingGlass={strapiGmHeader.BandeauHeader.MoteurRecherche}
            onSelectItem={() => {
              navigate("/app/profile")
            }}
          />
        </MiddleShrinked>
        <Right>
          <RightPart strapiGmHeader={strapiGmHeader} />
        </Right>
      </RawSmall>
      <NavBar className={getPopinClassName()} />
    </WrapperShrinked>
  }


  return (
    <CommonWrapperHeader>
      <Media lessThan="md">
        <HeaderMobile />
      </Media>
      <Media greaterThanOrEqual="md">
        <ShrinkManager ShrunkedComponant={HeaderDesktopShrunked} UnShrunkedComponant={HeaderDesktop}></ShrinkManager>
      </Media>

      <Popin
        className={getPopinClassName()}
        onClickOutside={e => {
          setCartOpen(false)
        }}
        title="Panier"
        width={isMobile ? "100%" : "30%"}
        minWidth={!isMobile ? "450px" : ""}
        height="100%"
      >
        <Cart
          handleClose={e => {
            setCartOpen(false)
          }}
        />
      </Popin>
    </CommonWrapperHeader>
  )
}

interface CartIconProps {
  urlIcon: string
  from: enumFrom
  setCartOpen: () => void
  numberOfItems: number
}



const RightPart = ({ strapiGmHeader }) => {
  const dispatch = useDispatch()
  const cartOpened = useSelector(state => state.headerStatusReducer.cartOpened)
  const setCartOpen = value => {
    dispatch(setHeaderStatus({ cartOpened: value }))
  }

  return (<Icons>
    <QuestionRespons>
      <Title>Une question ? <br />Besoin d'un conseil ?</Title>
      <Flex>
        <Bold>Appelez-nous au </Bold>
        <Tel href="tel:+33582950887">05 82 95 08 87</Tel>
      </Flex>
    </QuestionRespons>
    
    <CardIconWrapper
      setCartOpen={() => { setCartOpen(!cartOpened) }}
      urlIcon={strapiGmHeader.BandeauHeader.Panier}
      from={enumFrom.STRAPI}>
        
    </CardIconWrapper>
      
    <StyledIcon
      onClick={() => {
        navigate("/app/profile")
      }}
      data={strapiGmHeader.BandeauHeader.Utilisateur}
      from={enumFrom.STRAPI}

    />
  </Icons >)
}

const CardIconWrapper = ({ urlIcon, from, setCartOpen, }: CartIconProps) => {
  return (
    <>
      <Glissement>
        <StyledIcon
          onClick={() => {
            setCartOpen()
          }}
          data={urlIcon}
          from={from}
        />
      </Glissement>
      <Compteur setCartOpen={setCartOpen} />
    </>)

}

const Compteur = ({ setCartOpen }) => {
  const delay = 600
  const { numberOfItems } = useCartWithTotal()
  const [number, setNumber] = useState("")
  const timer = useRef(setTimeout(() => {
    setNumber(numberOfItems)
  }, 0))

  useEffect(() => {
    if (numberOfItems) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        setNumber(numberOfItems)
      }, delay);
    }

    return () => clearTimeout(timer.current);
  }, [numberOfItems]);

  return (
    <PastilleCompteur
      onClick={() => {
        setCartOpen()
      }}
    >
      {number}
    </PastilleCompteur>)
}

export const HeaderView = React.memo(HeaderViewCore)