import styled from "styled-components"

import { CommonParagraph } from "./../../../common/Texts"

export const Wrapper = styled(CommonParagraph)`
  margin: auto;
  width: 100%;
  height: 25px;
  z-index: 100;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ fontColor }) => fontColor};
  text-align: center;
  justify-content: center;
  &.mobile {
  }
`
