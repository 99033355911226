import styled from "styled-components"
import { CommonSubParagraph } from "../../Texts"
import { ImageWithLinkOrNot } from "./../../../common/ImageWithLinkOrNot"

export const CommonWrapperBar = styled.div``
export const Wrapper = styled.div``
export const WrapperMobile = styled.div`
  width: 100%;
  max-height: 4.75em;
  margin-top: 0;
  background-color: ${({ theme }) => theme.palette.background.default};
`

export const InputWrapper = styled.div`
  display: inline-flex;
  width: 98%;
  align-items: center;
  justify-content: space-around;
  margin: 0.5em 0;
`

export const ItemWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  width: 100%;
  &.active {
    background-color: #C4AB88;
  }
  &:hover {
    background-color: #C4AB88;
    border: solid 1px #d9d9d9;
  }
`
export const Input = styled.input`
  border-style: none;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.palette.text.primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  margin-left: 1em;
  &:focus {
    outline: none;
  }
  width: 90%;
`

export const InputMobile = styled(Input)`
  display: none;
  &.btn-menu-open {
    display: block;
    width: 100%;
  }
`

export const Search = styled(ImageWithLinkOrNot)`
  object-fit: contain;
  max-width: 25px;
  max-height: 25px;
  position: relative;
`
export const SearchMobile = styled(ImageWithLinkOrNot)`
  object-fit: contain;
  width: 25px;
  height: 25px;
  align-self: flex-start;
`
export const Results = styled.ul`
  position: absolute;
  background-color: ${({ theme }) => theme.palette.background.default};
  z-index: 5;
  z-index: 999;
  background-color: #f9f9f9;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 130%;
  overflow-y: auto;
  overflow-x: hidden;
  &.mobile {
    top: 3.5em;
    height: auto;
  }
`

export const ItemList = styled.li`
  list-style-type: none;
`
export const ProduitName = styled(CommonSubParagraph)`
  flex-basis: auto;
  align-self: flex-start;
  &.active, &:hover {
    color: white;
  }
  margin: auto;
  margin-left: 3em;
`
export const ProduitImageMobile = styled(ImageWithLinkOrNot)`
  border-radius: 10px;
  padding: 5px;
  width: 5em;
  flex-basis: 10em;
`

export const ProduitImage = styled(ImageWithLinkOrNot)`
  border-radius: 10px;
  padding: 5px;
  width: auto;
  height: auto;
  object-fit: cover;
  max-height: 4em;
  max-width: 5em;
  flex-basis: 10em;
`
export const MobileResult = styled.div`
  margin: 10px;
  margin-right: 30px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.palette.text.primary};
`
