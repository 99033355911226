import styled from "styled-components"

export const MainWrapper = styled.div`
  box-sizing: border-box;
`

export const Wrapper = styled.div`
  box-sizing: border-box;
  object-fit: cover;
  padding-top: 13em;
`
export const WrapperMobile = styled(Wrapper)`
  height: 100%;
  width: 100%;
  padding-top: 3.5em;
`

export const FatherOfMainWrapper = styled.div``

export const MainWrapperMobile = styled.div``

export const HeaderMobile = styled.div`
  padding-top: 2em;
  object-fit: cover;
  box-sizing: border-box;
`
