import styled from "styled-components"
import { Icon as IconBase } from "./../Icon"
import { CommonSubtitle } from "./../Texts"

export const Wrapper = styled.div`
  z-index: 150;
  top: 0px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease 0s;
  background-color: rgba(44, 46, 44, 0.8);
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 1em 5%;
  justify-content: space-between;
  align-items: center;
  height: 70px;
`

export const Title = styled(CommonSubtitle)`
  margin: 0;
`

export const Icon = styled(IconBase)`
  padding-right: 3%;
  cursor: pointer;
`

export const Content = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  max-height: 100%;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  background-color: ${({ theme }) => theme.palette.background.default};
  box-shadow: ${({ theme }) => theme.shadows[2]};
  ${({ minWidth }) => (minWidth ? "min-width:" + minWidth + ";" : "")};
  &.mobile {
    overflow-y: auto;
    width: 90vw;
    max-width: 30em;
  }
`
