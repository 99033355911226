import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { initDataSearchEngine } from "../../../../entities/searchEngine/dataSearchEngine.reducer"
import { setHeaderStatus } from "./../../../../entities/headerStatus/headerStatus.actions"
import { SearchBarView } from "./SearchBar.view"

interface Props {
  placeholder?: string
  magnifyingGlass?: Record<string, any>
  className?: string
}

export function SearchBarContainer({
  className,
  placeholder = "",
  magnifyingGlass = {},
}: Props) {
  const dispatch = useDispatch()
  const searchActive = useSelector(
    state => state.headerStatusReducer.searchActive
  )
  const [startSearchDataPending, setStartSearchDataPending] = useState(false)
  const inputValue = useRef("")

  const toggleSearchActive = () => {
    const data = {
      searchActive: !searchActive,
    }
    dispatch(setHeaderStatus(data))
  }

  const queryData = useStaticQuery(graphql`
    query {
      localSearchPages {
        publicIndexURL
        publicStoreURL
      }
    }
  `)

  const indexUrl = queryData.localSearchPages?.publicIndexURL
  const storeUrl = queryData.localSearchPages?.publicStoreURL
  const [storeAndIndex, setStoreAndIndex] = useState(
    null as unknown as { index: any; store: any }
  )
  const dataSearchEngine = useSelector(
    state => state.dataSearchEngineReducer.dataSearchEngine
  )
  const isDataSearchEngineEmpty = () =>
    !dataSearchEngine?.index || !dataSearchEngine?.store

  const SearchDataFetch = async () => {
    try {
      const index = await fetchDataString(indexUrl)
      const store = await fetchDataJson(storeUrl)
      dispatch(initDataSearchEngine({ index, store }))
      setStartSearchDataPending(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {

    if (!isDataSearchEngineEmpty()) {
      setStoreAndIndex(dataSearchEngine)
    }
  }, [dataSearchEngine])

  useEffect(() => {
    if (
      indexUrl &&
      storeUrl &&
      startSearchDataPending &&
      isDataSearchEngineEmpty()
    ) {

      SearchDataFetch()
    }
  }, [startSearchDataPending])

  const handleClick = () => {

    if (isDataSearchEngineEmpty()) {
      setStartSearchDataPending(true)

    }
  }

  return (
    <SearchBarView
      onClickHandler={handleClick}
      inputValue={inputValue.current}
      className={className}
      placeholder={placeholder}
      magnifyingGlass={magnifyingGlass}
      toggleSearchActive={toggleSearchActive}
      index={storeAndIndex?.index}
      store={storeAndIndex?.store}
    />
  )
}

async function fetchDataJson(url: string) {
  const response = await fetch(url)

  if (!response.ok) {
    throw new Error(response.statusText)
  }
  const body = await response.json()
  return body
}
async function fetchDataString(url: string) {
  const response = await fetch(url)

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  const body = await response.body
  const reader = await body.getReader()
  let done = false
  let text = ""
  while (!done) {
    let result = await reader.read()
    let chunck = new TextDecoder().decode(result.value)
    text += chunck
    done = result.done
  }

  return text
}
