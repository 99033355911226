import React from "react"
import { useDetectDevice } from "../../../lib/useDetectDevice"
import { Wrapper, Item, Img, Text, Text1 } from "./InfoBar.style"

interface Props {
  data: Record<string, any>
  className?: string
}

export function InfoBarView({ data, className }: Props) {
  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")
  return (
    <Wrapper className={classNames}>
      <Item>
        <Img data={data.strapiGmHeader.BandeauReassurance.Image1} />
        <Text>{data.strapiGmHeader.BandeauReassurance.Text1}</Text>
      </Item>
      <Item>
        <Img data={data.strapiGmHeader.BandeauReassurance.Image2} />
        <Text>{data.strapiGmHeader.BandeauReassurance.Text2}</Text>
      </Item>
      <Item>
        <Img data={data.strapiGmHeader.BandeauReassurance.Image3} />
        <Text>{data.strapiGmHeader.BandeauReassurance.Text3}</Text>
      </Item>
      {/* <Item className={isMobile ? "mobile" : ""}>
        <Img data={data.strapiGmHeader.BandeauReassurance.Image4} />
        <Text1>{data.strapiGmHeader.BandeauReassurance.Text4}</Text1>
      </Item> */}
    </Wrapper>
  )
}
