import React from "react"
import { Wrapper, Title, ZohoIframe } from "./SubscriptionBox.style"
import { useDetectDevice } from "../../../../lib/useDetectDevice"

interface Props {
  className?: string
  news?: string
}

export function SubscriptionBoxView({ className, news = "" }: Props) {
  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")

  return (
    <>
      <script
        type="text/javascript"
        src="https://campaigns.zoho.eu/js/zc.iframe.js"
      />
      <Wrapper className={classNames}>
          <Title
            small={news}
            large="NewsLetter"
            smallSize={1}
            largeSize={2.6}
          />
          <ZohoIframe
            id="iframewin"
            src="https://lpbk.maillist-manage.eu/ua/Optin?od=12ba7e51ddd7&zx=14ac44d07f&lD=112b2abad73f7d01&n=11699f74ed4ffae&sD=112b2abad73fcc58"
          />
      </Wrapper>
    </>
  )
}
