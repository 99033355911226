import React from "react"
import { InfoBarView } from "./InfoBar.view"
import { useInfoBarQuery } from "./InfoBar.query"

interface Props {
  className?: string
}

export function InfoBarContainer() {
  const data = useInfoBarQuery()
  return <InfoBarView data={data} />
}
