import styled, { keyframes } from "styled-components"

const openingx = keyframes`
  from {bottom: 0;}
  to {bottom: -1000px;}
`

const closingx = keyframes`
  from {top: 0;}
  to {top: -100%;}
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  color: ${({ theme }) => theme.palette.text.primary};
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  div {
    margin-top: 0.15em;
  }
  &.open {
    animation: ${openingx} 3s cubic-bezier(1, 0, 0, 1);
    bottom: 0;
  }
  &.close {
    animation: ${closingx} 2s cubic-bezier(1, 0, 0, 1);
    top: -100%;
  }
`

export const SousMenu = styled.div``

export const Div = styled.div`
  &:first-child,
  &:last-child {
  }
`
