import styled from "styled-components"
import { CommonWrapper } from "../CommonWrapper"

export const WrapperCounter = styled.div`
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  &.mobile {
    padding: 0 0.5rem;
  }
`

export const Total = styled.div`
  font-size: 22px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 600;
`

export const Button = styled.button`
  background-color: #CECECE;
  font-size: 20px;
  font-weight: ${({ theme }) => theme.typography.button.fontWeight};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  width: 31px;
  height: 31px;
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: 10px;

  border: none;
  &:hover {
    opacity: 0.5;
  }
  outline: none;
  cursor: pointer;
`
export const StyledCommonWrapper = styled(CommonWrapper)`
  width: auto;
`
