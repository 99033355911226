import React, { useEffect, useState } from "react";



export interface ShrunkManagerProps {
  ShrunkedComponant: JSX.Element;
  UnShrunkedComponant: JSX.Element;
}


export const ShrinkManager = ({ ShrunkedComponant, UnShrunkedComponant }: ShrunkManagerProps) => {
  const [isShrunk, setShrunk] = useState(false);

  const valueTriggerShrink = 50;
  const valueTriggerUnShrink = 20;

  const handler = () => {
    setShrunk((isShrunk) => {
      if (!isShrunk &&
        (document.body.scrollTop > valueTriggerShrink ||
          document.documentElement.scrollTop > valueTriggerShrink)) {
        return true;
      }

      if (isShrunk &&
        document.body.scrollTop < valueTriggerUnShrink &&
        document.documentElement.scrollTop < valueTriggerUnShrink) {
        return false;
      }

      return isShrunk;
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);

  if (isShrunk) return <ShrunkedComponant />
  else return <UnShrunkedComponant />

};
