import React from "react"
import { CartView } from "./Cart.view"

interface Props {
  className?: string
  handleClose: Function
  redirectUrl?: string
  onLoadMore: () => void
}

export function CartContainer(props: Props) {
  return (
    <CartView {...props} />
  )
}
