import React, { useRef, ReactNode } from "react"
import { useDetectDevice } from "../../../lib/useDetectDevice"
import { Wrapper, Row, Title, Icon, Content } from "./Popin.style"

interface Props {
  className?: string
  onClickOutside?: Function
  title?: string
  minWidth?: string
  width?: string
  height?: string
  top?: string
  left?: string
  withCloseBtn?: boolean
  children: ReactNode
}

export function PopinView({
  className,
  children,
  title = undefined,
  minWidth,
  width = undefined,
  height = undefined,
  top = undefined,
  left = undefined,
  withCloseBtn = true,
  onClickOutside = () => { },
}: Props) {
  const contentRef = useRef()
  const isMobile = useDetectDevice()
  const handleClickOutside = e => {
    if (contentRef.current && contentRef.current.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    onClickOutside()
  }

  return (
    <Wrapper onClick={handleClickOutside} className={className}>
      <Content
        className={isMobile && "mobile"}
        ref={contentRef}
        width={width && width}
        minWidth={minWidth && minWidth}
        height={height && height}
        top={top && top}
        left={left && left}
      >
        {withCloseBtn && (
          <Row>
            <Title>{title && title}</Title>
            <Icon icon="cross" onClick={onClickOutside} />
          </Row>
        )}
        {children}
      </Content>
    </Wrapper>
  )
}
